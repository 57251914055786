.loginDiv {
    width: 90%;
    margin: 10vh auto;
}

.loginHeading {
    width: 60%;
    font-family: Poppins-Bold;
    margin-bottom: 25%;
}

.loginGoogleBtn {
    margin: 0 auto;
}

.loginBtn {
    background-color: red;
    border: transparent;
    border-radius: 8px;
    padding: 10px 25px;
    margin: 10% auto 5% auto;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.loginBtn:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px red;
}