.genreCard {
    display: flex;
    align-items: center;
    text-decoration: none;
    box-shadow: 1px 1px 5px white;
    justify-content: space-between;
    margin: 10px;
    padding-right: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px gray;
    transition: shadow .5s linear;
}

.genreCardLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 10px;
    border-radius: 10px;
    transition: shadow .5s linear;
    width: 90%;

}

.genreCard-interactionButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    width: 10%;
}

.genreCard-bookmark:hover {
    cursor: pointer;
}



.genreCard:hover {
    box-shadow: 2px 2px 12px gray;
}

.genreCard-poster {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 55px;
    height: 83px;
    border-radius: 10px;
    margin: 10px 10px;
}

.genreCard-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 .5rem;
    width: 100%;
    color: #000000;
}

.genreCard-movieTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin: 10px 0;
}

.genreCard-movieTitle article {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.genreCard-releaseYear {
    display: flex;
    width: 100%;
    margin: 10px 0;
}

.genreCard-title {
    font-weight: 700;
    font-size: 18px;
}

.starImg {
    height: 20px;
}

.genreCard-rating {
    padding-right: 15%;
}

.genreCard-movieText {
    display: flex;
    align-items: center;
}

.genreCard-bookmark {
    width: 25px;

    height: 30px;
}