.Favorites-Wrapper {
    width: 90%;
    padding-bottom: 20%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 0 80px 0
}

.FavoritesBtnAndHeading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Favorites-Wrapper h1 {
    margin: 40px 0px;
    align-self: center;
    font-family: Poppins-Bold;
    font-size: 1.2rem;
}

.favoritesBackButton {
    height: 40px;
    width: 40px;
    outline: none;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    background-color: #FBF8FF;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 6px gray;
    align-items: center;
    cursor: pointer;
}

.favoritesBackButton:hover {
    box-shadow: 3px 3px 10px gray;

}

.favoritesBackButton img {
    height: 35px;
    width: 25px;
    transform: rotate(180deg);
}