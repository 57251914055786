/* Slideshow */

.trendingCard-slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 350px;
}

.trendingCard-slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;

}

.trendingCard-slide {
    display: inline-block;
    height: 350px;
    width: 350px;
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.trendingCard-TextContent {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;
    color: white;
    padding: 20px 20px;
    flex-direction: column;
    gap: 5px;
    background: linear-gradient(0deg, black, transparent);
    border-radius: 40px;
}

.trendingCard-TextContent h3 {
    word-wrap: normal;
    white-space: pre-wrap;
}

.trendingCard-TextContent p {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    gap: 5px;
}

/* Buttons */

.trendingCard-slideshowDots {
    text-align: center;
}

.trendingCard-slideshowDot {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.trendingCard-slideshowDot.active {
    background-color: #fc2121;
}

.trendingCard-wrapper a {
    text-decoration: none;
    color: #fc2121;
}

.trendingCard-headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5% 0
}