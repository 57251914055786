.home-wrapper {
    padding: 20px 20px 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100vh; */
}

.home-wrapper h1 {
    margin: 40px 0px;
    align-self: flex-start;
    font-family: Poppins-Bold;
}