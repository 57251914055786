.detailBackGroundContainer {
    width: 100%;
    height: 68vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-Repeat: no-repeat;
    background-Size: contain;
    background-Attachment: fixed;
}

.detailPage {
    padding-bottom: 20%;
}

.backArrow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 0 0;
    font-size: 1.5rem;
    margin: 0 auto;
    color: white;
    font-weight: bolder;
}

.detailBackButton {
    height: 44px;
    width: 44px;
    border-radius: 15px;
    filter: drop-shadow(1px 1px 3px white)
}

.detailBackButton:hover {
    filter: drop-shadow(3px 3px 10px white);
    cursor: pointer
        /* box-shadow: 1px 1px 10px gray; */
}

.backArrow button {
    background-color: transparent;
    border-style: none;
}

.addToFavIcon {
    height: 35px;
}

.detailName {
    height: 68vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5rem;
}

.detailName p {
    font-size: 1.4em;
}

.detailHeadingRating {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.detailHeadingRating h2 {
    text-align: center;
    font-size: 2rem;
}

.detailReleaseRuntime {
    display: flex;
}

.starRating {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 5%;
}

.detailGenreDiv {
    display: flex;
    gap: 45px
}

.detailGenre {
    font-weight: bolder;
    margin-right: 2%;
}

.detailGenreGenresOutput {
    display: flex;
    flex-wrap: wrap;
}

.detailGenreP {
    white-space: nowrap;

}

/* .detailGenreDiv p:nth-of-type(3)::before,
.detailGenreDiv p:nth-of-type(4)::before,
.detailGenreDiv p:nth-of-type(5)::before,
.detailGenreDiv p:nth-of-type(6)::before {
    content: ", ";
} */

.detailOverviewDiv {
    width: 90vw;
    padding: 5% 0 10% 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detailOverviewDiv h3 {
    font-size: 1.5rem;
}

.seeMore {
    color: red;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    appearance: none;
    white-space: nowrap;
}

.seeMore::before {
    content: "See more..."
}

.seeMore:checked::before {
    content: "See less..."
}

.detailCuttofP:has(+ .seeMore:checked) {
    height: none;
    display: block
}


.detailLanguageP {
    font-weight: bolder;
    margin-right: 3%;
}

.watchTrailer {
    background-color: red;
    border: transparent;
    border-radius: 8px;
    padding: 15px 30px;
    margin: 10% auto 5% auto;
    color: white;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
}


.detailCuttofP {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
    /* max-height: calc(var(5)*1em*var(1.5)) */
}


.detailIframe {
    width: 80%;
    display: flex;
    margin: 10% auto;
}

@media only screen and (min-width: 800px) {
    * {
        font-size: 20px;
    }

    .detailBackGroundContainer {
        width: 100%;
        background-size: cover;
    }

    /* .trendingCard-slide {
        width: 500px;
    } */
}