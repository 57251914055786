.SearchGenre-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;
}

.sortBtnDiv {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.sortBtn {
    padding: 15px 19px;
    margin: 10px 10px;
    background-color: #FBF8FF;
    border-radius: 5px;
    outline: none;
    border: none;
    white-space: nowrap;
}

.sortBtn.active {
    background-color: #fd8f8f;
}

.sortBtn:hover {
    background-color: #fd8f8f;
    cursor: pointer;
}